<template>
  <div class="container mx-auto ">
    <div class="max-w-8xl py-4 pb-8 flex">
      <div class="w-full">
        <full-calendar
          :options="calendarOptions"
          :events="events"
          class="text-black-50"
          @dateClick="showTaskDetail(calendarOptions.events.event)"
          
        ></full-calendar>
      </div>
      <!-- Task Detail Sidebar -->
    <div
      class="
        flex
        justify-end
        max-w-xl
        hidden
        fixed
        z-10
        taskDetail
        right-0
        overflow-none
      "
    >
      <aside
        class="border border-black-200 bg-white-100 w-screen"
        aria-label="Sidebar"
      >
        <div class="flex flex-col h-screen overflow-y-auto bg-white-100">
          <header
            class="
              header
              border-b border-black-200
              py-4
              px-2
              flex
              justify-end
              items-center
            "
          >
            
            <div class="flex items-center">
              <div class="mr-4">
                <i
                  v-if="myAbilities.can('store_task_documents', 'pms')"
                  class="fas fa-paperclip text-black-50"
                  @click="toggleDocModal"
                ></i>
              </div>
              <nav class="flex pr-4" aria-label="Breadcrumb">
                <ol class="inline-flex items-center space-x-1 md:space-x-3">
                  <li class="inline-flex items-center">
                    <router-link
                      :to="{
                        name: 'MyCalendarDetail',
                      }"
                      class="
                        inline-flex
                        pr-2
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Details
                    </router-link>
                    |
                  </li>
                  <li class="inline-flex items-center">
                    <router-link
                      :to="{
                        name: 'myCalendarQaStatus',
                        params: {
                          qaData: currentTaskQa,
                        },
                      }"
                      :param="currentTaskQa"
                      class="
                        inline-flex
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Q/A
                    </router-link>
                  </li>
                  <li class="inline-flex items-center">
                    |
                    <router-link
                      :to="{
                        name: 'myCalendariew',
                        params: {
                          reviewData: this.currentTaskReview,
                        },
                      }"
                      class="
                        inline-flex
                        pl-2
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Review
                    </router-link>
                  </li>
                </ol>
              </nav>
              <button class="ml-3" @click="hideDetail">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </header>
          <main class="flex-1 overflow-y-auto">
            <router-view :default="{ name: 'MyCalendarDetail' }" @update-task-name="updataTaskName"
              @update-task-desc="updataTaskDescription"> </router-view>
          </main>
           <!-- Comments -->
          <div class="py-1 px-5 space-x-2 rounded-b shadow-md">
            <div class="flex justify-end pt-16">
              <button
                class="p-2 bg-blue-500 text-white-100 font-bold rounded-lg"
                @click="addComment"
              >
                Comment
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>

     <!-- document Modal -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="taskDocModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Add Files
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleDocModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="addTaskDoc">
              <input
                class="
                  block
                  w-full
                  mb-5
                  text-sm text-gray-900
                  border border-gray-300
                  rounded-lg
                  cursor-pointer
                  bg-white-100
                  focus:outline-none
                "
                id="default_size"
                type="file"
                @change="mapFile"
              />
              <div class="flex items-center"></div>

              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import $ from 'jquery';
// import 'fullcalendar/dist/fullcalendar.css'

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "MyCalendar",
  
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        events: [],
        eventColor: "red",
        eventClick: this.showTaskDetail,
        selectable: true,
        // eventDidMount: function (arg) {
        //   console.log(arg, "mflkm");
        // },
      },

      events: [],
      eventColor: "red",
      Tasks: [],
      doc: {
        file: null,
      },
      currentTaskQa: {},
      currentTaskReview: {},

    };
  },
  components: {
    FullCalendar,
  },
  methods: {
    ...mapActions(["fetchMyTasks", "fetchTask", "updateMyTask", "storeTaskDocument"]),
...mapMutations(["toggleLoading"]),


// task detail sidebar
async showTaskDetail(event) {
  console.log(event, "task ");

  console.log(event.event._def.publicId, "task id");
  let id = event.event._def.publicId;
      // let projectId = task.project.id;

      // this.crntTask = task;
      this.currentTaskId = event.event._def.publicId;
      this.$router
        .push({ name: "MyCalendarDetail", params: { taskId: this.currentTaskId } })
        .catch(() => {});

         await this.fetchTask({ id });
  let sidebar = $(".taskDetail");
      if (sidebar.hasClass("hidden")) {
        sidebar.removeClass("hidden");
      }
},

// hide detail sidebar
    hideDetail() {
      console.log(this.$route.params.id, "routes");
      let stateObj = { id: "22" };
      window.history.replaceState(stateObj, "MyTask", `/myTask/myTaskCalendar`);

      $(".taskDetail").toggleClass("hidden");
    },

// for current qa task, for qa status
    newFunction() {
      if (this.Task.task_qa.length != 0) {
        let taskQa = this.Task.task_qa;
        let currentTaskQa = {};
        // console.log(taskQa, "Qa list");
        taskQa.forEach((element) => {
          if (element.end_time == null) {
            currentTaskQa = element;
          }
        });
        this.currentTaskQa = currentTaskQa;
        console.log(currentTaskQa, "current task qa");
      }
    },
     // For current review task, Review status

    currentReview() {
      if (this.Task.task_review.length != 0) {
        let taskReview = this.Task.task_review;
        let currentTaskReview = {};
        // console.log(taskQa, "Qa list");
        taskReview.forEach((element) => {
          if (element.end_time == null) {
            currentTaskReview = element;
          }
        });
        this.currentTaskReview = currentTaskReview;
        console.log(currentTaskReview, "current task review");
      }
    },
//----- update task ----------
     async updataTaskName(name, id) {
      // console.log(e,id);
      this.toggleLoading(true);
      let res = await this.updateMyTask({
        id: id,
        name: name,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    //update task description
    async updataTaskDescription(desc, id, name) {
      // console.log(e.target.value, id);
      this.toggleLoading(true);
      let res = await this.updateMyTask({
        id: id,
        name: name,
        description: desc,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
    },
    // add Document
    async addTaskDoc() {
      console.log(this.$route.params, "param ");
      this.toggleLoading(true);
      let formdata = new FormData();
      formdata.append("file", this.doc.file);
      formdata.append("task_id", this.$route.params.taskId);

      let res = await this.storeTaskDocument(formdata);
      console.log(formdata, "file");
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.doc.file = "";
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleDocModal();
    },
// task comment
     async addComment() {
      this.toggleLoading(true);
      let res = await this.storeTaskComment({
        description: this.comment.description,
        task_id: this.$route.params.id,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.comment.description = "";
      } else {
        this.$toasted.error(res.msg);
      }
    },

    // for open and close document modal
    toggleDocModal() {
      console.log(this.$route.params.taskId, "route param Kanaban");
      document.getElementById("taskDocModal").classList.toggle("hidden");
    },
    mapFile(e) {
      this.doc.file = e.target.files[0];
    },
 
  },
  
 async created() {
  this.toggleLoading(true);
  await this.fetchMyTasks();
    this.Tasks = this.myTask;
    this.Tasks.filter((el) => {
      this.calendarOptions.events.push({
        tid: el.id,
        title: el.name,
        start: el.deadline,
        end: el.end_date,
        id: el.id,
        borderColor: "black",
        backgroundColor: 'red',
      });
      // }
    });
  this.toggleLoading(false);
    this.events = this.myTask;
  },
  async mounted(){
    this.$eventBus.$on("callMethodIndetail", () => {
      this.showTaskDetail;
    });
    
    let url = window.location.href;
    let segment = url.split("/").pop();
    if (segment == "taskCalendarDetail") {
      $(".taskDetail").removeClass("hidden");
    }
    if (segment == "myTaskCalendarreview") {
      $(".taskDetail").removeClass("hidden");
    }
    if (segment == "myTaskCalendarQastatus") {
      $(".taskDetail").removeClass("hidden");
    }
  },

  computed: {
    ...mapGetters(["myTask", "myAbilities", "Task"]),
  },
  watch: {
    Tasks: {
      handler(value) {
        this.calendarOptions.events = [];
        value.filter((el) => {
          this.calendarOptions.events.push({
            tid: el.id,
            title: el.name,
            start: el.deadline,
            id: el.id,
            borderColor: "black",
            backgroundColor: 'red'
          });
        });
      },
    },
  },
};
</script>